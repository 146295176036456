<template>
  <div>
    <div class="page-container pb-0 mb-2">
      <filter-component
        @filterDistrictCommune="handleFilter($event)"
      />
    </div>
    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :hideDelete="false"
        :hideImportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        class="custtom-header pr-1"
        @clickExportExcel="dowloadFile"
        @search="search($event)"
      />
      <!--Phần header của bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
      />
      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />
    </div>
  </div>

</template>

<script>
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import store from '../../store'
import FilterComponent from './component/FilterComponent.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constansts/ConstanstsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    FilterComponent,
  },
  data() {
    return {
      // ==dữ liệu bảng
      dataList: [],
      columns: [
        {
          label: 'HUYỆN',
          field: 'districtName',
        },
        {
          label: 'XÃ',
          field: 'wardName',
        },
        {
          label: 'SỐ LƯỢNG LAO ĐỘNG',
          field: 'value',

        },
      ],
      // ==phân trang
      urlQuery: {
        search: '',
        district: null,
        wardId: null,
        pageSize: 10,
        pageNumber: 1,
      },
      totalRecord: 0,
      currentPage: 10,
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },

  methods: {
    async dowloadFile() {
      const name = 'ThongKeTheoCapXaHuyen.xlsx'
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_EXCEL)
    },
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_DIVISION, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchData(this.urlQuery)
      }
    },

    // xử lý filter
    handleFilter(val) {
      if (val.valueDistrict != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.district = val.valueDistrict
        this.urlQuery.wardId = val.valueCommune
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.district = null
        this.urlQuery.wardId = null
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style>

</style>
